<template>
  <div
    class="videoBox"
    :style="{
      width:this.width,
      height:this.height,
    }">
    <div class="">
      <slot></slot>
    </div>
    <div
      v-if="openCamera && status == 1 && !GetKernelStateObj.WorkMode"
      class="thumbnailBox"
      :style="{
        width:this.width,
        height:this.height,
        lineHeight:this.height,
      }">
      <img
        class="thumbnailImg movetarget"
        :src="imgUrl"
        :onerror="errorImgUrl"
        v-drag="dragEvent"
        :maxLeft="maxLeft"
        :minLeft="minLeft"
        :maxTop="maxTop"
        :minTop="minTop"
        :dragAble="isHavePan.manual && openCamera"
        @click="showFocusIcon($event)"
        :style="{left: videoL + 'px', top: videoT + 'px', transform: cameraCss.scaleVal, filter: cameraCss.brightVal}">
    </div>
    <!-- <div class="hide test">{{pageAnyWheresocket}}</div> -->
    <div
      class="m3u8Box"
      :style="{width:this.width,height:this.height}"
      v-show="(GetKernelStateObj.WebRVideoSolution === 100 || GetKernelStateObj.WebRVideoSolution == undefined) && GetKernelStateObj.WorkMode">
      <video
        id="vid1"
        preload="auto"
        ref="videoPlayer"
        class="video-js vjs-default-skin movetarget videoLabelCheckCookie"
        v-drag="dragEvent"
        :maxLeft="maxLeft"
        :minLeft="minLeft"
        :maxTop="maxTop"
        :minTop="minTop"
        :dragAble="isHavePan.manual && openCamera"
        @click="showFocusIcon($event)"
        :style="{ left: videoL + 'px', top: videoT + 'px', transform: cameraCss.scaleVal, filter: cameraCss.brightVal}" />
      <div class="viewBox"></div>
    </div>
    <div
      class="time"
      v-show="showTimeOrVideo && !GetKernelStateObj.SDI">
      {{this.GetKernelStateObj.CurrentTimestamp}}
    </div>
    <img
      class="backgroundImg"
      :src="backgroundImg"
      v-show="showBackgroundImg">
    <div
      class='flvBox'
      :style="{width:this.width,height:this.height}"
      v-show="GetKernelStateObj.WebRVideoSolution === 200 && GetKernelStateObj.WorkMode">
      <video
        class="flvjs movetarget videoLabelCheckCookie"
        autoplay
        width="100%"
        v-drag="dragEvent"
        :maxLeft="maxLeft"
        :minLeft="minLeft"
        :maxTop="maxTop"
        :minTop="minTop"
        :dragAble="isHavePan.manual && openCamera"
        @click="showFocusIcon($event)"
        :style="{ left: videoL + 'px', top: videoT + 'px', transform: cameraCss.scaleVal, filter: cameraCss.brightVal}" />
      <div class="viewBox"></div>
    </div>
    <div
      class='webrtcBox'
      :style="{width:this.width,height:this.height}"
      v-show="GetKernelStateObj.WebRVideoSolution === 300 && (GetKernelStateObj.WorkMode || !executeRtcLeaveCopy) && socketItemData?.status != 1">
      <video
        v-if="GetKernelStateObj.WorkMode || !executeRtcLeaveCopy"
        class="movetarget videoLabelCheckCookie"
        ref="videoObj"
        autoplay
        muted
        v-drag="dragEvent"
        :maxLeft="maxLeft"
        :minLeft="minLeft"
        :maxTop="maxTop"
        :minTop="minTop"
        :dragAble="isHavePan.manual && openCamera"
        @click="showFocusIcon($event)"
        @contextmenu.prevent="rightClick"
        :style="{ left: videoL + 'px', top: videoT + 'px', transform: cameraCss.scaleVal, filter: cameraCss.brightVal}" />
      <div
        class="viewBox"
        :class='{sdi_bg: GetKernelStateObj.SDI}'></div>
      <div class="hide">{{ showControlsCurrent }}</div>
    </div>
    <div
      ref="rtcInfoBox"
      class="iceBox"
      v-show="infoVisible">
      <div
        v-for="(value, key, index) in rtcInfo"
        :key='index'>
        <span class="infoSpan">{{key}}: </span>
        <span>{{value}}</span>
      </div>
    </div>
    <div class="hide">{{GetKernelStateObj.WebRVideoSolution + ':' + GetKernelStateObj.WorkMode + ":" + !executeRtcLeaveCopy}}
    </div>
    <div
      class='sourceInfo'
      v-show="showWarnInfo">{{$t('lang.moreThanLimitation', [maxPreviewNumber])}}</div>
    <div
      style="position:absolute;z-index:1000;top:0;"
      class="hide">{{GetKernelStateObj.WorkMode + ':' + !executeRtcLeaveCopy}}
    </div>
    <div
      class="transcriber"
      v-show="isShowTrans"
    >
      <span class="redFColor">Transcribing...</span>
    </div>
    <i
      @click="changeIconStatus"
      class="iconfont"
      v-show="GetKernelStateObj.WebRVideoSolution === 300 && GetKernelStateObj.WorkMode && needShowMuteIcon && (GetKernelStateObj.SDI ? stream : true)"
      :class="showMuteIcon()"></i>
    <div
      class="liveOnHoldBg"
      v-show="GetKernelStateObj && GetKernelStateObj.WorkMode == 1 && socketItemData?.status == 1 && liveOnHoldFlag">
      {{ $t("lang.live_OnHold") }}
    </div>
    <div
      class="anyWhereShaw"
      v-if="openCamera">
      <div
        class="scaleVideo"
        v-if="openCamera">
        <img
          class="addScale zoomIcon"
          src="../assets/img/iconfontfangda.png"
          @mouseup="holdUp('add')"
          @mousedown="holdDown('add')">

        <el-slider
          v-model="scaleValue"
          tooltip-class="exposure-tips"
          @change="changeZoom()"
          style="width: 300px"
          :min="0"
          :max="100"
          :step="1">
        </el-slider>
        <img
          class="cutScale zoomIcon"
          src="../assets/img/suoxiao.png"
          @mouseup="holdUp('cut')"
          @mousedown="holdDown('cut')">
      </div>
      <div
        class="dimming"
        v-if="openCamera">
        <div
          class="exposure"
          v-show="showPuGuang">
          <span class="sliderBtn">{{brightness}}</span>
          <el-slider
            v-model="brightness"
            tooltip-class="exposure-tips"
            @change="changeBright()"
            style="width: 150px"
            :min="-50"
            :max="50"
            :step="1">
          </el-slider>
        </div>
        <span
          class="sanjiao"
          v-show="showPuGuang"></span>
        <i
          class="iconfont icon-puguang"
          @click="showSunAdjust"></i>
      </div>
    </div>

    <div
      class="focusAndExposure"
      :style="{left: this.cameraCss.focusLeft,top: this.cameraCss.focusTop}"
      v-show="openCamera && videoImgClicked">
      <img
        class="focusIcon"
        src="../assets/img/icon_focus.png">
    </div>
    <div
      v-show="bottomFlag && openCamera && isHavePan.manual"
      class="bottomBorder boundaryImg"></div>
    <div
      v-show="topFlag && openCamera && isHavePan.manual"
      class="topBorder boundaryImg"></div>
    <div
      v-show="leftFlag && openCamera && isHavePan.manual"
      class="leftBorder boundaryImg"></div>
    <div
      v-show="rightFlag && openCamera && isHavePan.manual"
      class="rightBorder boundaryImg"></div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import videojs from 'video.js'
import wpjs from '@/assets/js/wp.js'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'
import { setTimeout, clearTimeout } from 'timers'

export default {
  data () {
    return {
      videoBoxCss: {
        width: this.width,
        height: this.height,
        lineHeight: this.height
      },
      showWarnInfo: false,
      loginTimer: null,
      m3u8Player: null,
      m3u8LastTime: -1,
      m3u8Url: '',
      maxPreviewNumber: 5,
      initM3u8: true,
      initFlv: true,
      flvPlayer: null,
      wsHeader: null,
      firstLogin: true,
      initWebrtc: true,
      webrtcPlayer: null,
      mountedIcon: true,
      noMutedIcon: false,
      hasChangeMute: false,
      videoIntervalTimer: null,
      executeRtcLeaveCopy: this.executeRtcLeave,
      // camera相关
      imgUrl: '',
      errorImgUrl: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      openCamera: false,
      brightness: 0,
      scaleValue: 0,
      videoImgClicked: false,
      videoClickTimer: null,
      stream: true,
      cameraCss: {
        focusLeft: 0,
        focusTop: 0,
        scaleVal: 'scale(1)',
        brightVal: 'brightness(1)'
      },
      bottomFlag: false,
      topFlag: false,
      leftFlag: false,
      rightFlag: false,
      videoL: 0,
      videoT: 0,
      viewH: Number(this.height.replace('px', '')), // 可视区域/拖拽对象宽
      viewW: Number(this.width.replace('px', '')), // 可视区域/拖拽对象高
      phoneW: 0, // anywhere手机屏幕宽
      phoneH: 0, // anywhere手机屏幕高
      panW: 0, // anywhere手机屏幕线框宽
      panH: 0, // anywhere手机屏幕线框高
      remainH: 0,
      remainW: 0,
      maxLeft: 0,
      minLeft: 0,
      maxTop: 0,
      minTop: 0,
      isHavePan: {
        manual: true,
        auto: false
      },
      initTimeout: null,
      webrNumber: 300,
      updateImgTimer: null,
      changeIcon: true,
      rtcInfo: {},
      infoVisible: false,
      getRtcInfoTimer: null,
      showControlsCurrent: this.showControls == false,
      showPuGuang: false,
      pressTime: null,
      timeStart: null,
      timeEnd: null,
      isShowTrans: false,
      transcribeTimer: null
    }
  },
  props: {
    deviceType: {
      type: String,
      default: 'T'
    },
    width: {
      type: String,
      default: '664px'
    },
    height: {
      type: String,
      default: '373px'
    },
    selectRIp: {
      type: String,
      default: '10.12.23.202'
    },
    source: {
      type: Object,
      default: function () {
        return { msg: 'Hello' }
      }
    },
    rid: {
      type: String,
      default: ''
    },
    sid: {
      type: String,
      default: ''
    },
    // 为了在advance页面一进来就可以看到视频新增一个传值
    executeRtcLeave: {
      type: Boolean,
      default: true
    },
    // 这个字段用来判断是否用旧的保存R返回值的字段
    useOldR: {
      type: Boolean,
      default: false
    },
    delayVal: {
      type: Number,
      default: 2
    },
    status: {
      type: String,
      default: '0'
    },
    clearSelectR: {
      type: Boolean,
      default: false
    },
    needShowMuteIcon: {
      type: Boolean,
      default: true
    },
    isMultiContol: {
      type: Boolean,
      default: false
    },
    showControls: {
      type: Boolean,
      default: false
    },
    socketItemData: Object
  },
  computed: {
    ...mapState({
      State: state => state,
      ReceiverInfoObj1: state => state.ReceiverInfoObj,
      GetKernelStateObj1: state => state.GetKernelStateObj,
      websocket: state => state.websocket,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      liveOnHoldFlag: state => state.featureFlag.liveOnHoldFlag
    }),
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc
    }),
    GetKernelStateObj () {
      if (this.useOldR) {
        return this.GetKernelStateObj1
      } else {
        const rList = this.States.deviceListCurrentRInfoData
        let data = {}
        rList.map(v => {
          const currentRid = v.rid
          if (this.rid == currentRid && v.data) {
            data = JSON.parse(v.data)
            if (window.location.href.includes('/hm')) {
              if (!data.CurrentTStrIdHex || data.CurrentTStrIdHex.toLowerCase() != this.source.peerId) data = {}
            }
          }
        })
        return data
      }
    },
    ReceiverInfoObj () {
      if (this.useOldR) {
        return this.ReceiverInfoObj1
      } else {
        const rList = this.States.deviceListReceiverDataData
        let data = {}
        rList.map(v => {
          const currentRid = v.rid
          if (this.rid == currentRid && v.data) {
            data = JSON.parse(v.data)
          }
        })
        return data
      }
    },
    showTimeOrVideo () {
      if (this.ReceiverInfoObj.ProductBuildVersion) {
        // 当R的版本小于7.2并且R没有在live时，显示时间
        if (this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3) >= 7.2) {
          // executeRtcLeave用来判断当R时online的状态要不要去发送on_leave命令
          if (!window.location.href.includes('hm') && !window.location.href.includes('nhm')) {
            this.executeRtcLeaveCopy = false
            return false
          }
          if (this.isMultiContol) {
            this.executeRtcLeaveCopy = true
            return true
          }
          return false
        } else {
          this.executeRtcLeaveCopy = true
          if (this.GetKernelStateObj.WorkMode) {
            return false
          } else {
            if (window.location.href.includes('nhm')) {
              return false
            } else {
              return true
            }
          }
        }
      } else {
        this.executeRtcLeaveCopy = true
        return true
      }
    },
    backgroundImg () {
      if (this.source.status === '0') {
        return require('@/assets/img/offlinePng.png')
      } else {
        return require('@/assets/img/logo_thumbnail.png')
      }
    },
    showBackgroundImg () {
      // if (window.location.href.includes('nhm')) {
      if (this.ReceiverInfoObj.ProductBuildVersion) {
        // 当R的版本小于7.2并且R没有在live时，显示时间
        if (this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3) >= 7.2) {
          return true
        } else {
          if (this.source.status != '2') {
            return true
          } else {
            return false
          }
        }
      } else {
        return true
      }
      // } else {
      //   return false
      // }
    }
  },
  watch: {
    GetKernelStateObj: {
      handler () {
        if (this.GetKernelStateObj.WorkMode != 1) {
          if (this.webrtcPlayer && this.executeRtcLeaveCopy) {
            this.defaultConfig()
          }
        }
      },
      deep: true
    },
    /*
         * 选择操作camera方式
         * type 00——126——初始化
         *      10——124——手动关
         *      11——124——手动开
         *      20——128——自动关
         *      21——128——自动开
         *      30——***——关闭camera功能,此时需要关闭websocket
         *      40——125——重置
         */
    status (val, oldVal) {
      if (val == 1 && this.openCamera) {
        this.executeRtcLeaveCopy = true
        this.updataImg()
      } else if (val == 3 && this.openCamera) {
        if (this.websocket.pageAnyWheresocket) {
          this.$createWebsocket.pageAnyWheresocket('off', 'anywhere')
        }
        this.openCamera = false
        Bus.$emit('changePanBtnInit', 'close')
      } else if (val == 2 && this.openCamera) {
        if (this.websocket.pageAnyWheresocket) {
          this.$createWebsocket.pageAnyWheresocket('off', 'anywhere')
        }
        this.$createWebsocket.pageAnyWheresocket('', 'anywhere', this.sid)
        setTimeout(() => {
          this.sendAnywhereInfo('126', 1, this.sid)
        }, 1000)
      }
    }
  },
  created () {
    if (this.$route.path.indexOf('rc') > -1) {
      this.transcribeTimer = setInterval(() => {
        this.showTranscriber()
      }, 1000)
    }
    // 之前所以没有写到defaultConfig里面是为了各种情况
    Bus.$on('clearVideoInterval', () => {
      if (this.videoIntervalTimer) clearInterval(this.videoIntervalTimer)
    })

    Bus.$on('controlModuleChangeVideoC', (val) => { // 监听showInfo信息
      this.showControlsCurrent = val
      this.$nextTick(() => {
        if (this.$refs.videoObj) {
          this.$refs.videoObj.controls = this.showControlsCurrent
        }
      })
    })

    // 宽高初始化
    this.viewW = $('.videoBox').width()
    this.viewH = $('.videoBox').height()
    window.addEventListener('resize', () => {
      this.viewW = $('.videoBox').width()
      this.viewH = $('.videoBox').height()
      if (this.openCamera) {
        this.calculateInit()
      }
    })
    Bus.$on('mySourceHide', () => {
      this.openCamera = false
    })
  },
  mounted: function () {
    Bus.$on('changePanOperatetype', (val) => {
      switch (val) {
        case '00':
          this.openCamera = !this.openCamera
          if (!this.websocket.pageAnyWheresocket || this.websocket.pageAnyWheresocket.closed) {
            this.$createWebsocket.pageAnyWheresocket('', 'anywhere', this.sid)
            setTimeout(() => {
              this.sendAnywhereInfo('126', 1, this.sid)
            }, 1000)
          }
          break
        case '10':
          this.sendAnywhereInfo('124', 0, this.sid, val)
          break
        case '11':
          this.sendAnywhereInfo('124', 1, this.sid, val)
          break
        case '20':
          this.sendAnywhereInfo('128', 0, this.sid, val)
          break
        case '21':
          this.sendAnywhereInfo('128', 1, this.sid, val)
          break
        case '30':
          this.openCamera = false
          this.executeRtcLeaveCopy = false
          if (this.updateImgTimer) {
            clearInterval(this.updateImgTimer)
          }
          if (this.websocket.pageAnyWheresocket) {
            this.$createWebsocket.pageAnyWheresocket('off', 'anywhere')
          }
          break
        case '40':
          this.sendAnywhereInfo('125', 1, this.sid, val)
          break
        default:
          break
      }
    })
    // 恢复初始设置
    Bus.$on('selectOtherR', (rid, notChangeRStatus) => {
      this.defaultConfig()
      if (this.$refs.videoObj) {
        this.$refs.videoObj.muted = true
      }
    })
    Bus.$off('clearVideo')
    Bus.$on('clearVideo', () => {
      this.GetKernelStateObj.WorkMode = 0
      this.initWebrtc = true
    })
    this.startVidoeInterval()
  },
  methods: {
    ...mapMutations('home', [
      'INIT_LOGIN'
    ]),
    changeVideoVoice (val) {
      this.hasChangeMute = true
      this.mountedIcon = !this.mountedIcon
      this.noMutedIcon = !this.noMutedIcon
      if (this.$refs.videoObj) {
        if (val) {
          this.$refs.videoObj.muted = true
        } else {
          this.$refs.videoObj.muted = false
        }
      }
    },
    returnVideoObj () {
      const player = this.GetKernelStateObj.WebRVideoSolution === 300 ? 'webrtc' : 'm3u8'
      const videoObj = {}
      if (player === 'webrtc') {
        videoObj.this = this
        videoObj.obj = this.$refs.videoObj
      } else if (player === 'm3u8') {
        videoObj.this = this
        videoObj.obj = this.$refs.videoPlayer
      }
      return videoObj
    },
    // 循环去查询状态播放视频
    startVidoeInterval () {
      if (this.videoIntervalTimer) clearInterval(this.videoIntervalTimer)
      this.videoIntervalTimer = setInterval(() => {
        this.checkPlayVideo()
      }, 2000)
    },
    // 根据R的返回值来决定用什么播放器
    checkPlayVideo () {
      if (this.deviceType === 'R') {
        if (this.GetKernelStateObj && this.GetKernelStateObj.WorkMode === 1 || !this.executeRtcLeave) {
          if (window.location.href.includes('mv') && !window.location.href.includes('mvp') && this.$refs.videoObj) { // 为new multiview设置controls
            this.$refs.videoObj.controls = this.showControlsCurrent
          }
          switch (this.GetKernelStateObj.WebRVideoSolution) {
            case 100:
              this.playVideoByVideojs()
              break
            case 200:
              this.playVideoByFlv()
              break
            case 300:
              this.playVideoByWerbrtc()
              break
          }
        }
      } else {
        if ((this.GetKernelStateObj.WorkMode === 1 && (this.GetKernelStateObj.CurrentTStrIdHex.toLowerCase() == this.source.peerId || this.source.peerId == null)) || (!this.executeRtcLeaveCopy && this.ReceiverInfoObj.ProductBuildVersion ? this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3) >= 7.2 : '')) {
          switch (this.GetKernelStateObj.WebRVideoSolution) {
            case 100:
              this.playVideoByVideojs()
              break
            case 200:
              this.playVideoByFlv()
              break
            case 300:
              this.playVideoByWerbrtc()
              break
          }
          if (this.GetKernelStateObj.WebRVideoSolution == undefined) {
            console.log('anywhere')
            this.playVideoByVideojs()
          }
        } else {
          // this.playVideoByWerbrtc();
        }
      }
    },
    // 用videojs去播放m3u8的视频流
    playVideoByVideojs () {
      if (this.m3u8Player == null) {
        const player = videojs('vid1', {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: false,
          controlBar: true
        }, function () {
          this.on('ready', function () {
            this.play()
          })
        })
        const rIp = this.selectRIp || this.State.selectRIp
        const src = this.src = 'http://' + rIp + ':' + this.GetKernelStateObj.WebRM3U8PlaybackUrl
        player.src(src)
        player.load(src)
        this.m3u8Player = player
      } else {
        const currentTime = this.m3u8Player.currentTime()
        if (currentTime == this.m3u8LastTime) {
          this.m3u8Player.pause()
          this.m3u8Player.dispose()
          this.m3u8Player = null
        } else {
          this.m3u8LastTime = currentTime
        }
      }
    },
    // 用flv插件来播放flv格式视频流
    async playVideoByFlv () {
      let element
      if (this.initFlv) {
        this.initFlv = false
        element = $('.flvjs')[0]
        if (element.error == null) {
          if (element.paused) {
            this.wsHeader = wpjs.createWSReader(element)
            // new WebPlayer()
            this.flvPlayer = wpjs.createPlayer({
              isLive: true,
              lazyLoadMaxDuration: 60,
              seekType: 'range'
            })

            // init mse-controller/reader/muxer in player
            this.flvPlayer.init(element)
            this.wsHeader.onReconnect = this.flvPlayer.reset.bind(this.flvPlayer)
            this.wsHeader.onMetadataArrived = this.flvPlayer.onMetadataReceived.bind(this.flvPlayer)
            this.wsHeader.onVideoSampleArrived = this.flvPlayer.appendVideoSample.bind(this.flvPlayer)
            this.wsHeader.onAudioSampleArrived = this.flvPlayer.appendAudioSample.bind(this.flvPlayer)
            if (this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl == '') {
              return
            }
            const url = await this.checkVideoUrl()
            this.wsHeader.open(url)
          }
        }
      }
    },
    // 判断视频播放地址
    async checkVideoUrl () {
      const _self = this; const websocketArray = []; let realVideoUrl = ''
      const index = _self.GetKernelStateObj.WebRM3U8PlaybackUrl.indexOf('/')
      const testUrl = _self.GetKernelStateObj.WebRM3U8PlaybackUrl.slice(index)

      return new Promise((resolve, reject) => {
        if (_self.ReceiverInfoObj.LocalIPAddresses.length > 0) {
          _self.ReceiverInfoObj.LocalIPAddresses.map(async (v, i) => {
            const url = await this.checkInnerUrl(v, websocketArray[i], testUrl)
            if (url) resolve(url)
          })
        } else {
          realVideoUrl = 'ws://' + _self.ReceiverInfoObj.ExternalIPAddresses + ':' + _self.ReceiverInfoObj.NginxExternalPort + testUrl
          resolve(realVideoUrl)
        }
      })
    },
    // 判断内网播放地址
    async checkInnerUrl (ip, websocketBox, testUrl) {
      let url = ''; const _self = this
      return new Promise((resolve, reject) => {
        url = 'ws://' + ip + ':' + _self.ReceiverInfoObj.NginxExternalPort + testUrl
        websocketBox = new WebSocket(url)
        websocketBox.onopen = () => {
          resolve(url)
          websocketBox.close()
        }
        websocketBox.error = () => {
          websocketBox.close()
          resolve('')
        }
      })
    },
    // 用webrtc去播放视频
    playVideoByWerbrtc () {
      if (this.Webrtc.websocket.hadLogin) {
        if (this.initWebrtc && (this.GetKernelStateObj.WorkMode == '1' || !this.executeRtcLeaveCopy)) {
          this.initWebrtc = false
          this.stream = false
          this.$emit('hasStream', this.stream)
          const array = []
          array.push(this.GetKernelStateObj.SDI ? this.GetKernelStateObj.WebRSDIFeedUrl : this.GetKernelStateObj.WebRM3U8PlaybackUrl)
          if (array.length == 0) return
          // 设置video type 即可
          const videoObj = { type: 'video' }
          this.Webrtc.startCall(array, videoObj, (result) => {
            if (result.type === 'success') {
              this.stream = true
              this.showWarnInfo = false
              this.$store.state.playSourceInfo = result.msg.mediaStream
              if (this.$refs.videoObj) {
                this.$refs.videoObj.srcObject = result.msg.mediaStream
                this.$refs.videoObj.disablePictureInPicture = true
              }
            } else if (result.type === 'disconnected' || result.type == 'failed') {
              if (this.ReceiverInfoObj.ProductBuildVersion) {
              // console.log(this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3))
                if (this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3) >= 7.2) {
                  if (this.$refs.videoObj && this.GetKernelStateObj.SDI) {
                    this.GetKernelStateObj.SDI = false
                    this.initWebrtc = true
                    this.Webrtc.stopCall(this.GetKernelStateObj.WebRSDIFeedUrl)
                    this.playVideoByWerbrtc()
                  }
                }
              }
            } else if (result.type === 'moreThanLimitation') {
              const arrlimit = result.msg.errorMsg.split(' ')
              this.maxPreviewNumber = arrlimit[arrlimit.length - 1]
              this.showWarnInfo = true
              this.stream = false
            }
            this.$emit('hasStream', this.stream)
          })
          this.webrtcPlayer = this.GetKernelStateObj.SDI ? this.GetKernelStateObj.WebRSDIFeedUrl : this.GetKernelStateObj.WebRM3U8PlaybackUrl
        }
      }
    },
    rightClick (mouseEvent) {
      this.infoVisible = false// 确保窗口时关闭的
      this.infoVisible = true
      this.$refs.rtcInfoBox.style.left = mouseEvent.offsetX + 10 + 'px'
      this.$refs.rtcInfoBox.style.top = mouseEvent.offsetY + 10 + 'px'
      document.addEventListener('click', this.foo)

      if (this.getRtcInfoTimer) clearInterval(this.getRtcInfoTimer)
      if (this.GetKernelStateObj.WorkMode) {
        this.Webrtc.connBrief(this.GetKernelStateObj.WebRM3U8PlaybackUrl)
          .then((data) => {
            this.rtcInfo = data
          })
        this.getRtcInfoTimer = setInterval(() => {
          this.Webrtc.connBrief(this.GetKernelStateObj.WebRM3U8PlaybackUrl)
            .then((data) => {
              this.rtcInfo = data
            })
        }, 1000)
      }
    },
    foo () {
      this.infoVisible = false
      document.removeEventListener('click', this.foo)
      if (this.getRtcInfoTimer) clearInterval(this.getRtcInfoTimer)
    },
    // 因为各个浏览器为了用户体验会禁止用webrtc自动播放视频,所以创建视频时都是静音的，为了能恢复声音加的icon图标.
    showMuteIcon () {
      // 先判断是否手动更改过
      if (this.$refs.videoObj) {
        if (!this.hasChangeMute) {
          return 'muteIcon'
        } else {
          if (this.mountedIcon) {
            return 'muteIcon'
          } else {
            return 'noMuteIcon'
          }
        }
      }
    },
    showTranscriber () {
      if ((this.GetKernelStateObj.WorkMode == 1 || this.GetKernelStateObj.IsPreview || this.State.isFilePreview) && this.State.hasTranscriberFeature && this.ReceiverInfoObj.StrId) {
        this.pageRWebsocket.send({
          message: { categoryId: '2152868608', operationType: '101', rid: this.ReceiverInfoObj.StrId.toLowerCase() },
          key: 'transcribeSetting',
          isOnce: true,
          success: res => {
            if (res.data && JSON.parse(res.data)) {
              this.isShowTrans = JSON.parse(res.data).IsEnabled
            }
          }
        })
      } else {
        this.isShowTrans = false
      }
    },
    // 更改静音图标
    changeIconStatus () {
      this.hasChangeMute = true
      this.mountedIcon = !this.mountedIcon
      this.noMutedIcon = !this.noMutedIcon
      let muted = 'Off'
      if (this.$refs.videoObj) {
        if (!this.hasChangeMute) {
          this.$refs.videoObj.muted = true
        } else {
          if (this.mountedIcon) {
            this.$refs.videoObj.muted = true
          } else {
            this.$refs.videoObj.muted = false
          }
        }
      }
      if (!this.mountedIcon) {
        muted = 'On'
      }
      myReceiversAnalyze(`myReceiversMuteVolume${muted}`, this.$route.path) // 谷歌统计 打开/关闭声音
    },
    defaultConfig () {
      // console.log("执行清除视频")
      if (this.m3u8Player) {
        this.m3u8Player.pause()
        this.m3u8Player.dispose()
        this.m3u8Player = null
        this.$refs.videoPlayer = null
      }
      // console.log('webrtc视频： ' + this.webrtcPlayer);
      if (this.webrtcPlayer) {
        this.Webrtc.stopCall(this.webrtcPlayer)
        this.webrtcPlayer = null
      }
      if (this.flvPlayer) {
        if (this.wsHeader) {
          this.wsHeader.destroy()
        }
        if (!this.flvPlayer.paused) {
          this.flvPlayer.pause()
        }
        this.flvPlayer.detachMediaElement()
        this.flvPlayer.destroy()
        this.flvPlayer = null
      }
      this.initFlv = true
      this.initWebrtc = true
      this.showWarnInfo = false
      if (this.changeIcon) {
        this.hasChangeMute = false
        this.mountedIcon = true
        this.noMutedIcon = false
      }

      // 清空视频
      if ((window.location.href.includes('/rc?') || window.location.href.includes('/hm?')) && !this.clearSelectR) {
        this.GetKernelStateObj.WorkMode = 0
      }
    },
    // 更新Anywhere camera中的缩略图
    updataImg () {
      if (this.updateImgTimer) {
        clearInterval(this.updateImgTimer)
      }
      if (this.status == 1 && this.openCamera) {
        this.imgUrl = window.location.protocol + '//' + this.$store.state.common.baseUrl.blobUrl + '/' + this.sid.toUpperCase() + '?' + Math.random()
        if (this.isHavePan.manual) {
          this.updateImgTimer = setInterval(() => {
            this.imgUrl = window.location.protocol + '//' + this.$store.state.common.baseUrl.blobUrl + '/' + this.sid.toUpperCase() + '?' + Math.random()
          }, 1500)
        }
      }
    },
    // 拖拽事件
    dragEvent (obj) {
      this.videoL = obj.x
      this.videoT = obj.y
      this.calculateLimit(obj.x, obj.y)
    },
    moveHideBox () {
      if (this.initTimeout) clearTimeout(this.clearAnyTimeout)
      let intervalTime = 6000
      if (this.webrNumber == '300') {
        intervalTime = this.delayVal * 1000 + 500
      }
      this.initTimeout = setTimeout(() => {
        this.videoL = 0
        this.videoT = 0
        this.topFlag = this.bottomFlag = this.leftFlag = this.rightFlag = false
      }, intervalTime)
    },
    // 计算范围
    calculateLimit (x, y, type) {
      if (type == 'init') return
      // 判断是否到达边界
      this.leftFlag = x == this.maxLeft
      this.topFlag = y == this.maxTop
      this.rightFlag = x == this.minLeft
      this.bottomFlag = y == this.minTop
      /*
      * 按比例计算通过websocket发送给anywhere
      * 未到边界时发送中心点坐标
      */
      let dragCenterL = parseInt(this.remainW - x * (this.phoneW / this.viewW))
      let drageCenterT = parseInt(this.remainH - y * (this.phoneH / this.viewH))
      if (x == this.maxLeft) { // 超出左边界时X为0
        dragCenterL = 0
      }
      if (y == this.maxTop) { // 超出上边界时Y为0
        drageCenterT = 0
      }
      if (x == this.minLeft) { // 超出右边界时Y为0
        dragCenterL = this.phoneW - this.panW
      }
      if (y == this.minTop) { // 超出右边界时Y为0
        drageCenterT = this.phoneH - this.panH
      }
      this.sendAnywhereInfo('123', [dragCenterL, drageCenterT], this.sid)
      this.remainW = dragCenterL
      this.remainH = drageCenterT
      this.calculateInit()
      // 将dragBox移动到viewBox中
      this.moveHideBox()
    },
    // 自适应计算范围
    calculateInit () {
      this.maxLeft = parseInt(this.remainW / (this.phoneW / this.viewW))
      this.minLeft = -parseInt((this.phoneW - this.panW - this.remainW) / (this.phoneW / this.viewW))
      this.maxTop = parseInt(this.remainH / (this.phoneH / this.viewH))
      this.minTop = -parseInt((this.phoneH - this.panH - this.remainH) / (this.phoneH / this.viewH))
    },
    // 处理websocket返回值信息
    handleAnywhereInfo (anywhereInfo) {
      const isAuto = anywhereInfo.isAuto
      this.phoneW = parseInt(anywhereInfo.bgWidth)
      this.phoneH = parseInt(anywhereInfo.bgHeight)
      this.panW = parseInt(anywhereInfo.width)
      this.panH = parseInt(anywhereInfo.height)
      this.remainW = (this.phoneW - this.panW) / 2
      this.remainH = (this.phoneH - this.panH) / 2
      // 计算可拖拽区域的宽高
      this.videoL = this.videoT = 0
      this.viewW = $('.videoBox').width()
      this.viewH = $('.videoBox').height()
      this.calculateInit()
      this.calculateLimit(0, 0, 'init')
      // 获取上次关闭时处于手动还是自动模式
      if (this.panW) {
        this.isHavePan.auto = !!isAuto
        this.isHavePan.manual = !isAuto
      } else {
        this.isHavePan.auto = false
        this.isHavePan.manual = false
      }
      Bus.$emit('changePanBtnInit', this.isHavePan)
      this.updataImg()
    },
    // 发送websocket信息
    sendAnywhereInfo (type, data, peerId, val) {
      const that = this
      let callbackFlag = false; let callbackTimer = null
      this.websocket.pageAnyWheresocket.send({
        message: { dataType: type, parameter: data, peerId: peerId, module: 'anywhere' },
        time: 1000,
        key: 'anywhere',
        isOnce: true,
        receMsg: res => {
          // manual:需打开放大缩小及焦点功能，允许拖拽
          const resM = JSON.parse(res)
          if (resM.data != 'heartbeat') {
            if (resM.dataType == '124') {
              callbackFlag = true
              this.topFlag = this.bottomFlag = this.leftFlag = this.rightFlag = false
              if (resM.result != 'Success') {
                const anywhereInfo = JSON.parse(resM.result)
                // 不支持
                if (anywhereInfo.bgWidth == 0) {
                  this.$message({
                    type: 'warning',
                    message: this.$t('lang.unSupport')
                  })
                  return
                }
                if (anywhereInfo.width) {
                  this.isHavePan.auto = resM.isAuto
                  this.isHavePan.manual = !this.isHavePan.auto
                } else {
                  this.isHavePan.auto = false
                  this.isHavePan.manual = false
                }
                Bus.$emit('changePanBtnInit', this.isHavePan)
                this.handleAnywhereInfo(anywhereInfo)
              }
            }
          }
        },
        success: res => {
          const resM = res
          const anywhereInfo = resM.result == 'Success' ? 'Success' : JSON.parse(resM.result)
          if (resM.dataType == '125') {
            // this.changeZoom()
            that.cameraCss.scaleVal = `scale(${1})`
            that.cameraCss.brightVal = `brightness(${1})`
            that.scaleValue = 0
            that.brightness = 0
          }
          if (resM.data != 'heartbeat' && resM.result != 'Success') {
            // 暂时没有返回值
            if (resM.dataType == '125') {
            }
            if (resM.dataType == '126') {
            }
            // auto:开启auto模式
            if (resM.dataType == '128' && val && val == '21') {
              callbackFlag = true
              this.handleAnywhereInfo(anywhereInfo)
            }
            // auto:关闭auto模式
            if (resM.dataType == '128' && val && val == '20') {
              callbackFlag = true
              this.handleAnywhereInfo(anywhereInfo)
            }
          }
        }
      })
      // 当切换手动及自动时，超过5秒不返回有效信息给出提示
      if (type == '124' || type == '128') {
        const _this = this
        if (callbackTimer != null) {
          clearTimeout(callbackTimer)
        }
        callbackTimer = setTimeout(() => {
          if (callbackFlag) {
            clearTimeout(callbackTimer)
          } else {
            _this.$message({
              type: 'warning',
              message: _this.$t('lang.anywhereTimeout')
            })
            Bus.$emit('changePanBtnInit', _this.isHavePan)
          }
        }, 5000)
      }
    },
    // 修改zoom
    changeZoom (val) {
      // console.log(9999)
      if (!this.openCamera) return
      if (typeof val != 'number') {
        if (val == 'add') {
          this.scaleValue = this.scaleValue + 1
        } else if (val == 'cut') {
          // this.scaleValue = this.scaleValue == 0? 0 : this.scaleValue - 10;
          this.scaleValue = this.scaleValue == 0 ? 0 : this.scaleValue - 1
        }
        // this.scaleValue = val == "add" ? this.scaleValue + 10 : this.scaleValue - 10;
      } else {
        this.scaleValue = val
      }
      const scaleNum = this.scaleValue / 40 + 1
      this.cameraCss.scaleVal = `scale(${scaleNum})`
      this.sendAnywhereInfo('121', this.scaleValue, this.sid)
    },
    holdUp (val) {
      clearInterval(this.pressTime)
      // console.log( this.timeEnd)
      if (this.timeEnd == null) {
        this.changeZoom(val)
      }
      this.timeEnd = null
    },
    holdDown (val) {
      this.timeStart = this.getTimeNow()
      let num = val
      if (typeof val != 'number') {
        if (val == 'add' || val == 'cut') {
          num = this.scaleValue
        }
      }

      this.pressTime = setInterval(() => {
        this.timeEnd = this.getTimeNow()
        if (val == 'add') {
          num++
        } else if (val == 'cut') {
          num--
        }
        if (num <= 0 || num >= 100) {
          clearInterval(this.pressTime)
        } else {
          this.scaleValue = num
          const scaleNum = this.scaleValue / 40 + 1
          this.cameraCss.scaleVal = `scale(${scaleNum})`
          this.sendAnywhereInfo('121', this.scaleValue, this.sid)
        }
      }, 100)
    },
    getTimeNow () { // 获取此刻时间
      const now = new Date()
      return now.getTime()
    },

    // 手动自动都可以修改定位
    showFocusIcon (event) {
      const currentTarget = $(event.currentTarget)
      const isClick = currentTarget.attr('clickAble')
      if (isClick !== 'true' || !this.openCamera) {
        this.videoImgClicked = false
        return false
      }
      if (this.videoClickTimer != null) {
        clearTimeout(this.videoClickTimer)
      }
      this.videoImgClicked = true
      let x; let y; let kx; let ky; let xPosition; let yPosition
      // 因为图片的大小是35px所以计算点的坐标时要带上
      x = event.offsetX - 17
      y = event.offsetY - 17
      this.cameraCss.focusTop = event.offsetY - 50 + 'px'
      this.cameraCss.focusLeft = event.offsetX - 17 + 'px'
      // 开启pan和不开启pan相对应的坐标系是不一样的，不开启pan是相当于4k左上角，开启是相当于里面小画面的左上角
      if (!this.isHavePan.manual || !this.isHavePan.auto) {
        kx = this.phoneW / this.viewW
        ky = this.phoneH / this.viewH
        xPosition = parseInt(x * kx)
        yPosition = parseInt(y * ky)
      } else {
        kx = this.panW / this.viewW
        ky = this.panH / this.viewH
        xPosition = this.panW + parseInt(x * kx)
        yPosition = this.panH + parseInt(y * ky)
      }
      if (xPosition >= 0 && yPosition >= 0) {
        this.sendAnywhereInfo('120', [xPosition, yPosition], this.sid)
      }
      this.videoClickTimer = setTimeout(() => {
        this.videoImgClicked = false
      }, 3000)
    },
    // 调整曝光度
    changeBright () {
      if (!this.openCamera) return
      const brightNum = this.brightness >= 0 ? 1 + (this.brightness / 10 * 0.6) : 1 + (this.brightness / 10 * 0.1)
      this.cameraCss.brightVal = `brightness(${brightNum})`
      this.sendAnywhereInfo('122', this.brightness, this.sid)
    },
    showSunAdjust () {
      this.showPuGuang = !this.showPuGuang
    }
  },
  // 拖拽指令
  directives: {
    drag: {
      inserted: (el, binding) => {
        const oDiv = el
        let firstTime = ''; let lastTime = ''
        oDiv.onmousedown = (ea) => {
          firstTime = new Date().getTime()
          ea.preventDefault()
          if (oDiv.getAttribute('dragAble') && oDiv.getAttribute('dragAble') != null) {
            const maxLeft = oDiv.getAttribute('maxLeft')
            const minLeft = oDiv.getAttribute('minLeft')
            const maxTop = oDiv.getAttribute('maxTop')
            const minTop = oDiv.getAttribute('minTop')
            //  鼠标按下，计算当前元素距离可视区的距离
            const disX = ea.pageX - oDiv.offsetLeft
            const disY = ea.pageY - oDiv.offsetTop
            document.onmousemove = (e) => {
              //  获取到鼠标拖拽后的横向位移(距离父级元素)
              let l = e.pageX - disX
              //  获取到鼠标拖拽后的纵向位移(距离父级元素)
              let t = e.pageY - disY
              l = l >= maxLeft ? maxLeft : l
              l = l <= minLeft ? minLeft : l
              t = t >= maxTop ? maxTop : t
              t = t <= minTop ? minTop : t
              oDiv.style.left = l + 'px'
              oDiv.style.top = t + 'px'
            }
          }
          /*
                     *  将此时的位置传出去
                     *  松开事件后，移除事件
                     */
          document.onmouseup = (e) => {
            document.onmousemove = null
            document.onmouseup = null
            lastTime = new Date().getTime()
            if ((lastTime - firstTime) >= 200) {
              oDiv.setAttribute('clickAble', false)
              if (oDiv.getAttribute('dragAble') && oDiv.getAttribute('dragAble') != null) {
                binding.value({
                  x: Number(oDiv.style.left.replace('px', '')),
                  y: Number(oDiv.style.top.replace('px', ''))
                })
              }
            } else {
              oDiv.setAttribute('clickAble', true)
            }
          }
        }
      }
    }
  },
  beforeDestroy () {
    if (this.videoIntervalTimer) clearInterval(this.videoIntervalTimer)
    if (this.transcribeTimer) clearInterval(this.transcribeTimer)
    if (this.updateImgTimer) clearInterval(this.updateImgTimer)
    if (this.websocket.pageAnyWheresocket) {
      this.$createWebsocket.pageAnyWheresocket('off', 'anywhere')
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', 'transcribeSetting')
    }
    this.defaultConfig()
    if (!this.clearSelectR) {
      Bus.$off('selectOtherR')
    }
    Bus.$off('clearVideoInterval')
    Bus.$off('startVideoInterval')
    Bus.$off('changePanOperatetype')
  }
}
</script>
<style lang="less" scoped>
.hide {
  display: none;
}

.videoBox {
  position: relative;
  text-align: center;
  background: #2f3330;

  .thumbnailBox {
    overflow: hidden;
    position: relative;
  }

  .dragBox {
    background: #fff;
    box-sizing: border-box;
    position: relative;
    line-height: 0;
  }

  .viewBox {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    z-index: 2;
    left: 0;
    top: 0;
  }

  .viewBox.sdi_bg {
    background: #2f3330;
    background-image: url(../assets/img/logo.png);
    background-size: 15%;
    background-repeat: no-repeat;
    background-position: 50% 40%;
  }

  .iceBox {
    position: absolute;
    background: #000;
    z-index: 3;
    left: 0;
    top: 0;
    opacity: 0.8;
    padding: 10px;
    text-align: left;

    .infoSpan {
      display: inline-block;
      width: 85px;
    }
  }

  .movetarget {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
  }

  .bottomBorder {
    bottom: -11px;
    left: 50%;
    transform: rotate(270deg) translateX(-50%);
  }

  .topBorder {
    top: -11px;
    left: 50%;
    transform: rotate(90deg) translateX(-50%);
  }

  .leftBorder {
    top: 50%;
    left: -2px;
    transform: translateY(-50%);
  }

  .rightBorder {
    top: 50%;
    right: -2px;
    transform: rotate(180deg) translateY(-50%+90px);
  }

  .boundaryImg {
    background: url(../assets/img/bottom.png) no-repeat;
    position: absolute;
    height: 45px;
    width: 14px;
    z-index: 6;
  }

  .time {
    font-size: 40px;
    position: absolute;
    width: 100%;
    // top: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  .backgroundImg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .m3u8Box {
    height: 100%;

    .video-js {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .flvBox {
    position: absolute;
    width: 100%;
    overflow: hidden;
  }

  .webrtcBox {
    // position:absolute;
    // width: 100%;
    position: relative;
    overflow: hidden;
  }

  .sourceInfo {
    position: absolute;
    z-index: 2;
    width: 100%;
    font-size: 18px;
    color: #ff9000;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    top: 50%;
  }
  .transcriber {
    position: absolute;
    line-height: 0;
    right: 20px;
    top: 20px;
    z-index: 10;
    .redFColor {
      animation: biling 4s infinite;
    }
  }
  .muteIcon,
  .noMuteIcon {
    position: absolute;
    line-height: normal;
    bottom: 10px;
    right: 15px;
    z-index: 100;
    z-index: 5;
  }

  .muteIcon:before {
    content: '\E615';
    font-size: 22px;
    color: green;
    cursor: pointer;
  }

  .noMuteIcon:before {
    content: '\E613';
    font-size: 22px;
    color: green;
    cursor: pointer;
  }

  .liveOnHoldBg {
    position: absolute;
    top: 0.16rem;
    left: 16px;
    z-index: 5;
    padding: 0 8px;
    height: 28px;
    border: none;
    background: #1ec345;
    border-radius: 4px;
    line-height: 28px;
    text-align: center;
    background: #FF9A24;
    color: #fff;
  }
  .scaleVideo {
    position: absolute;
    bottom: 3px;
    right: 25%;
    line-height: 0;
    z-index: 5;

    .zoomIcon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    .addScale {
      position: absolute;
      top: 8px;
      right: -26px;
    }

    .cutScale {
      position: absolute;
      top: 8px;
      left: -23px;
    }

    ::v-deep .el-slider__runway {
      height: 3px;
    }

    ::v-deep .el-slider__button-wrapper {
      width: 8px;
      height: 8px;
      top: -8px;
    }

    ::v-deep .el-slider__runway .el-slider__bar {
      height: 3px;
    }

    ::v-deep .el-slider__button {
      /*height: 6px;*/
      /*border-radius: 20%;*/
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      background: #dbdbdb;
    }

    ::v-deep .el-slider.is-vertical .el-slider__button-wrapper {
      left: -17px;
    }

    ::v-deep .el-slider.is-vertical .el-slider__runway {
      width: 3px;
      background: #dbdbdb;
    }

    ::v-deep .el-slider__runway .el-slider__bar {
      width: 3px;
      background-color: #dbdbdb;
    }
  }

  .anyWhereShaw {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    /*background: linear-gradient(180deg, #333333 0%, rgba(0, 0, 0, 0) 100%);*/
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 4;
  }

  .dimming {
    position: absolute;
    z-index: 8;
    right: 10%;
    bottom: 8px;
    cursor: pointer;

    .exposure {
      position: absolute;
      top: -32px;
      right: -34px;
      width: 186px;
      height: 26px;
      background: #333333;

      .sliderBtn {
        float: left;
        margin-top: 4px;
        margin-left: 6px;
        width: 20px;
        font-size: 10px;
        color: #dbdbdb;
        line-height: 21px;
      }

      ::v-deep .el-slider {
        float: left;
        margin-left: 6px;
      }

      ::v-deep .el-slider__runway {
        height: 3px;
        margin: 12px 0;
        background: #666666;
      }

      ::v-deep .el-slider__button-wrapper {
        width: 8px;
        height: 8px;
        top: -8px;
      }

      ::v-deep .el-slider__runway .el-slider__bar {
        height: 3px;
      }

      ::v-deep .el-slider__button {
        /*height: 6px;*/
        /*border-radius: 20%;*/
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        background: #dbdbdb;
      }

      ::v-deep .el-slider.is-vertical .el-slider__button-wrapper {
        left: -17px;
      }

      ::v-deep .el-slider.is-vertical .el-slider__runway {
        width: 3px;
        background: #dbdbdb;
      }

      ::v-deep .el-slider__runway .el-slider__bar {
        width: 3px;
        background-color: #dbdbdb;
      }
    }

    .sanjiao {
      width: 0;
      height: 0;
      border-top: 6px solid #333333; /*这行去掉也行*/
      border-bottom: 6px solid transparent;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      position: absolute;
      top: -6px;
    }

    .icon-puguang {
      font-size: 20px;
    }
  }

  .focusAndExposure {
    position: absolute;
    z-index: 8;
    line-height: 0;

    .focusIcon {
      height: 35px;
      width: 35px;
      display: inline-block;
    }

    .exposure {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;

      ::v-deep .el-slider.is-vertical .el-slider__button-wrapper {
        background: url('../assets/img/icon_exposure.png') no-repeat;
        left: -17px;
      }
    }
  }

  ::v-deep .el-slider.is-vertical .el-slider__runway {
    width: 3px;
    background: #e6b800;
  }

  ::v-deep .el-slider__runway .el-slider__bar {
    width: 3px;
    background-color: #e6b800;
  }

  ::v-deep .el-slider__button {
    border: none;
    background: #e6b800;
  }
}
@keyframes biling {
  from {
    color: red;
  }
  to {
    color: transparent;
  }
}
</style>
